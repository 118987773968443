<template>
  <div
    class="modal__overlay"
    @click="showEnterCode"
  />
  <div class="entercode-modal">
    <div class="container">
      <button
        class="closeModal"
        @click="showEnterCode"
      >
        <img
          src="@/assets/images/close_icon.svg"
          alt="Закрыть"
        >
      </button>

      <h3 class="entercode-modal__title">
        Code eingeben
      </h3>

      <p
        v-if="!timerEnd"
        class="entercode-modal__text"
      >
        Code gesendet 0:{{ countDown }}
      </p>

      <p
        v-else
        class="entercode-modal__text"
        @click="timerStart()"
      >
        Erneut senden
      </p>

      <div class="input__wrapper">
        <input
          id="code"
          v-model="code"
          v-maska="'##-##-##'"
          class="entercode-modal__input"
          type="phone"
          required
        >

        <label for="code">Vorwahl<span>*</span></label>
      </div>

      <button
        class="main-btn"
        :disabled="errorCode"
        @click="showEnterCode"
      >
        Anwenden
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Entercode',
  data() {
    return {
      countDown: 59,
      timerEnd: false,
      code: '',
      errorCode: true,
    }
  },
  watch: {
    code: function (val) {
      let code = this.code.replace(/[^\+\d]/g, '')
      if (code.length < 6) {
        this.errorCode = true
      } else {
        this.errorCode = false
      }
    },
  },
  mounted() {
    this.countDownTimer()
  },
  methods: {
    showEnterCode() {
      this.$emit('showEnterCode', { showEnterCode: false, code: this.code })
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.timerEnd = true
      }
    },
    timerStart() {
      this.countDown = 59
      this.timerEnd = false
      this.countDownTimer()
    },
  },
}
</script>

<style lang="scss">
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #dcdee3;
  opacity: 0.9;

  z-index: 1;
}

.entercode-modal {
  position: fixed;
  bottom: 45px;

  width: 100%;

  background-color: #fff;

  z-index: 2;
  border-radius: 12px 12px 0px 0px;

  & .container {
    padding-top: 12px;
  }

  & .closeModal {
    position: absolute;
    top: 16px;
    right: 16px;

    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;
    background: rgba(219, 222, 231, 0.5);
    cursor: pointer;

    border-radius: 50%;
    border: none;
  }

  &__title {
    margin-bottom: 24px;

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;

    color: #010d35;
  }

  &__text {
    margin-bottom: 24px;

    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;

    color: #6764ff;
  }

  &__link {
    //display: none;

    text-decoration: none;
  }

  & .input__wrapper {
    position: relative;
    margin-bottom: 24px;

    & label {
      position: absolute;
      top: 15px;
      left: 12px;

      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: #80869a;

      & span {
        color: red;
      }
    }
  }

  &__input {
    width: 100%;
    min-height: 52px;
    padding: 12px 12px 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #010d35;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    outline: none;

    &:focus + label {
      top: 8px;

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  & .main-btn {
    margin-bottom: 20px;
  }
}
</style>
